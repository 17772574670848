import {Fields, Model} from '@vuex-orm/core';
import Address from '../models/Address';

export default class BankAccount extends Model {
    public static modelName = 'BankAccount';
    public static entity = 'bank-accounts';

    public id: number;
    public merchantId: number;
    public customerId: number;
    public dateCreated: string;
    public dateUpdated: string;
    public dateDeleted: string;
    public accountType: number;
    public accountCorporate: number;
    public bankFinancialNumber: string;
    public bankTransitNumber: string;
    public bankRoutingNumber: string;
    public bankAccountNumber: string;
    public bankAccountL4L4: number;
    public firstName: string;
    public lastName: string;
    public companyName: string;
    public streetAddress: Address;
    public city: string;
    public province: string;
    public country: string;
    public postalCode: string;
    public verified: number;
    public ready: number;

    public get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    public get bankAccountL4(): string {
        return (this.bankAccountL4L4).toString().slice(-4);
    }

    public get bankTransitL4(): string {
        return (this.bankAccountL4L4).toString().replace(this.bankAccountL4, '');
    }

    public static fields(): Fields {
        return {
            id: this.attr(0),
            merchantId: this.attr(0),
            customerId: this.attr(0),
            dateCreated: this.attr(''),
            dateUpdated: this.attr(''),
            dateDeleted: this.attr(''),
            accountType: this.attr(0),
            accountCorporate: this.attr(0),
            bankFinancialNumber: this.attr(''),
            bankTransitNumber: this.attr(''),
            bankRoutingNumber: this.attr(''),
            bankAccountNumber: this.attr(''),
            bankAccountL4L4: this.attr(0),
            firstName: this.attr(''),
            lastName: this.attr(''),
            companyName: this.attr(''),
            streetAddress: this.hasOne(Address, 'id', 'addressId'),
            city: this.attr(''),
            province: this.attr(''),
            country: this.attr(''),
            postalCode: this.attr(''),
            verified: this.attr(0),
            ready: this.attr(0),
        };
    }

}
